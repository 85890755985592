import React, { useEffect } from "react";
import Map from "../components/Map";

import portada from "../frenteLemm.jpeg";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const Us = () => {
  const { t } = useTranslation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Us Page" });
  }, []);

  const backgroundImageStyle = {
    backgroundImage: `url("${portada}")`,
    backgroundSize: "cover",
    height: "inherit",
  };

  return (
    <div className="us-page">
      <div className=" text-white " style={backgroundImageStyle}>
        <div className="md:flex md:flex-row md:justify-around bg-gradient-to-r from-black pl-6 pr-1 py-12" style={{ height: "inherit", overflowY: "auto" }}>
          <div className="md:w-1/2 md:pr-2">
            <p className="h1 pb-2 font-bold">{t('Sobre nosotros')}...</p>
            <p className="font-semibold">
              {t('TEXTO SOBRE NOSOTROS p1')}
            </p>
            <p className="font-semibold">
              {t('TEXTO SOBRE NOSOTROS p2')}
            </p>
            <p className="font-semibold">
              {t('TEXTO SOBRE NOSOTROS p3')}
            </p>

          </div>

          <div className="mt-4">
            <Map />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Us;
