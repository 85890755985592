import TopBar from "./components/TopBar";
import MainSidebar from "./components/SideBar";
import { isMobile } from "react-device-detect";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-T69QTV7LSS";
ReactGA.initialize(TRACKING_ID);

export const useAnalyticsEventTracker = (category = "Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({ category, action, label });
  }
  return eventTracker;
}

function App(props) {

  return (
    <div className="flex flex-col w-full h-screen">
      <TopBar />
      <div className="flex flex-row grow">
        {!isMobile && <MainSidebar />}
        <div className={`main-content-layout`}>{props.element}</div>
      </div>
    </div>
  );
}

export default App;
