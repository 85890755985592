import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const productosApi = createApi({
  reducerPath: "productosApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_HOST || "http://localhost:8000"
      }/api/v1/`,
  }),
  tagTypes: ['productos'],
  endpoints: (build) => ({
    getMarcas: build.query({
      query: () => `marcas`,
    }),
    getModelosByMarca: build.query({
      query: ({ id }) => `marca/${id}/modelos`,
    }),
    getProductosByMarcaModelo: build.query({
      query(arg) {
        const { marcaId, modeloId } = arg;
        return {
          url: `productos-by-marca-modelo`,
          params: { marca_id: marcaId, modelo_id: modeloId }
        };
      },
      providesTags: ['productos']
    }),
    getProductosByCodigo: build.query({
      query(arg) {
        const { codigo } = arg;
        return {
          url: `productos-by-codigo`,
          params: { codigo }
        };
      },
      providesTags: ['productos']
    }),
    getProductosByTipo: build.query({
      query(arg) {
        const { tipo } = arg;
        const map = { "CDI": 1, "REGULADORES": 2, "BOBINAS": 3 }
        return {
          url: `productos-by-tipo`,
          params: { tipo: map[tipo] }
        };
      },
      providesTags: ['productos']
    }),
    getEquivalenciasByIdProducto: build.query({
      query(arg) {
        const { idProd } = arg;
        return {
          url: `equivalencias-by-producto-id`,
          params: { id: idProd }
        };
      },
      providesTags: ['productos']
    }),
  }),
});


export const {
  useGetMarcasQuery,
  useGetModelosByMarcaQuery,
  useGetProductosByMarcaModeloQuery,
  useLazyGetProductosByMarcaModeloQuery,
  useGetProductosByCodigoQuery,
  useLazyGetProductosByCodigoQuery,
  useGetProductosByTipoQuery,
  useLazyGetProductosByTipoQuery,
  useGetEquivalenciasByIdProductoQuery,
  useLazyGetEquivalenciasByIdProductoQuery,
} = productosApi;
