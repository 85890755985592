import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";

const Map = () => {
  const [loading, setLoading] = useState(true);

  const Skeleton = () => {
    return (
      <div
        className="bg-slate-400 rounded-lg"
        style={{
          width: "100%",
          height: 360,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner type="grow" color="danger" />
      </div>
    );
  };

  const hideShowSpinner = () => {
    setTimeout(() => {
      setLoading((i) => !i);
    }, 1500);
  };

  useEffect(() => {
    hideShowSpinner();
  }, []);

  return (
    <div className={"w-72 mt-6 md:mt-2 md:w-96"}>
      {loading ? (
        <Skeleton />
      ) : (
        <iframe
          className="rounded-lg"
          width="100%"
          height="360"
          src="https://maps.google.com/maps?width=100%25&amp;height=360&amp;hl=es&amp;q=Nicaragua%20Bis%20383%20S2000,%20S2007%20Rosario,%20Santa%20Fe+(Lemm)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          title="Dirección Lemm"
        ></iframe>
      )}
    </div>
  );
};

export default Map;
