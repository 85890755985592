import { React } from "react";
import { Carousel } from "flowbite-react";

import foto4439 from "../fotos_portada/4439.jpg";
import foto4460 from "../fotos_portada/4460.jpg";
import foto4502 from "../fotos_portada/4502.jpg";
import foto5502 from "../fotos_portada/5502.jpg";
import foto5538A from "../fotos_portada/5538A.jpg";

export const MyCarousel = () => {
  return (
    <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 w-[450px] shadow-xl">
      <Carousel>
        <img src={foto4439} alt="..." />
        <img src={foto4460} alt="..." />
        <img src={foto4502} alt="..." />
        <img src={foto5502} alt="..." />
        <img src={foto5538A} alt="..." />
      </Carousel>
    </div>
  );
};
