import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import ReactCountryFlag from "react-country-flag";
import { changeCurrentLanguage } from "../slices/AppSlice";
import { useTranslation } from "react-i18next";

const LanguageDropdown = () => {
  const { t } = useTranslation();
  const currentLanguage = useSelector((state) => state.app.language);
  const dispatch = useDispatch();
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen((old) => !old);
  };

  return (
    <Dropdown
      isOpen={languageDropdownOpen}
      toggle={toggleLanguageDropdown}
      direction={"down"}
      end={"true"}
    >
      <DropdownToggle
        style={{ display: "flex", alignItems: "center" }}
        tag={"div"}
        className="bg-[#8F1D14] rounded-md pl-1 pr-2 py-1 cursor-pointer"
      >
        <span className="text-xs">
          <ReactCountryFlag
            countryCode={currentLanguage}
            style={{
              fontSize: "2em",
              lineHeight: "2em",
            }}
          />
        </span>
      </DropdownToggle>
      <DropdownMenu dark className={"drop-shadow-xl"}>
        <DropdownItem
          active={currentLanguage === "US"}
          className="text-sm"
          onClick={() => dispatch(changeCurrentLanguage({ language: "US" }))}
        >
          <div className="flex flex-row gap-4 items-center">
            <ReactCountryFlag
              countryCode={"US"}
              style={{
                fontSize: "2em",
                lineHeight: "2em",
              }}
            />
            <span className="font-bold">{t("Inglés")}</span>
          </div>
        </DropdownItem>
        <DropdownItem
          active={currentLanguage === "AR"}
          className="text-sm"
          onClick={() => dispatch(changeCurrentLanguage({ language: "AR" }))}
        >
          <div className="flex flex-row gap-4 items-center">
            <ReactCountryFlag
              countryCode={"AR"}
              style={{
                fontSize: "2em",
                lineHeight: "2em",
              }}
            />
            <span className="font-bold">{t("Español")}</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
