import { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Spinner,
} from "reactstrap";
import EquivalenciasModal from "../components/EquivalenciasModal";
import SearchTypes from "../components/SearchTypes";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

export default function Search() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalProducto, setModalProducto] = useState({});
  const productos = useSelector((state) => state.app.productos);
  const isProductosLoading = useSelector((state) => state.app.isProductosLoading);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Search Page" });
  }, []);


  const toggleModal = (prod) => {
    setModalOpen(i => !i);
    setModalProducto(prod);
  }

  const renderCards = useMemo(() => {
    if (!productos) {
      return;
    }

    return productos.map((p) => {
      return (
        <Card
          color="light"
          className="drop-shadow-2xl hover:cursor-pointer product-card scale-100 hover:scale-105 ease-in duration-300"
          style={{
            width: "12rem",
            borderStyle: "solid",
            borderColor: "rgb(143, 29, 20)",
            borderWidth: "thin",
            borderRadius: 10
          }}
          key={p.codigo}
          onClick={() => toggleModal(p)}
        >
          <img alt="Sample" src={p.imagen} width={200} height={200} style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, height: "50%" }} />
          <CardBody style={{ backgroundColor: "rgb(143, 29, 20)", borderBottomLeftRadius: 9, borderBottomRightRadius: 9 }}>
            <CardSubtitle className="mb-2 text-slate-300" tag="h5">
              COD: {p.codigo}
            </CardSubtitle>
            <CardTitle tag="h6" className="text-white">{p.descripcion}</CardTitle>
          </CardBody>
        </Card>
      )
    }
    )
  }, [productos])

  return (
    <div className="search-page" style={{ overflowY: "auto" }}>
      <EquivalenciasModal toggle={toggleModal} modalOpen={modalOpen} producto={modalProducto} />
      <SearchTypes />
      {isProductosLoading && (
        <div className="w-full flex justify-center">
          <Spinner
            color="danger" style={{
              height: '3rem',
              width: '3rem',
              borderWidth: '6px',
            }} />
        </div>)}
      {!isProductosLoading && productos && (
        <div className="grid justify-items-center gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-5 grid-rows-3">
          {renderCards}
        </div>
      )}
    </div>
  );
}
