import { Footer } from "flowbite-react";
import { Link } from "react-router-dom";
import { MyCarousel } from "../components/Carousel";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useAnalyticsEventTracker } from "../App";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();
  const gaEventTracker = useAnalyticsEventTracker('Nuestros Productos');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Home Page" });
  }, []);

  return (
    <div style={{ overflowY: "auto", height: "inherit" }}>
      <div className="relative text-white " style={{ height: "inherit", overflowY: "auto" }}>
        <div className="px-8 pt-16 bg-slate-100" style={{ height: "inherit", overflowY: "auto" }}>
          <div className="flex flex-row flex-wrap justify-around">
            <div className="flex flex-row justify-center gap-8 flex-wrap w-full mb-40">
              <div className="max-w-xl grid grid-cols-1 gap-8 bg-gray-800 rounded-md p-6 shadow-xl">
                <h2 className="text-[34px] font-bold">
                  LEMM
                </h2>
                <h4>
                  {t('Desde 1990 brindando productos de calidad')}.
                </h4>
                <Link
                  to={"/search"}
                  href="#!"
                  onClick={() => gaEventTracker('click', 'Nuestros Productos button')}
                >
                  <button className="bg-gradient-to-r from-red-700 to-orange-600 py-3 px-6 text-lg rounded-md w-64 text-black">
                    <b className="text-white">{t('Nuestros productos')}</b>
                  </button>
                </Link>
              </div>
              <MyCarousel />
            </div>
          </div>
        </div>
      </div>
      <Footer className="absolute bottom-0 left-0">
        <div className="w-full text-left pl-2 bg-gray-800 text-white">
          <Footer.Copyright
            by="Andrés Botello"
            year={2023}
          />
        </div>
      </Footer>
    </div>
  );
}

export default Home;
