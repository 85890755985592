import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "Inicio": "Home",
            "Productos": "Products",
            "Nuestros productos": "Our Products",
            "Sobre nosotros": "About Us",
            "Nosotros": "Us",
            "Contactanos": "Contact Us",
            "Desde 1990 brindando productos de calidad": "Since 1990 providing quality products",
            "Busque productos en nuestro catálogo": "Look for products in our catalog",
            "Modelo": "Model",
            "Seleccione modelo": "Select model",
            "Seleccione marca y modelo": "Select brand and model",
            "Ingrese un código": "Enter a product code",
            "Seleccione un tipo de producto": "Select a product type",
            "Buscar": "Search",
            "Código": "Code",
            "Tipo": "Type",
            "Filtrar por...": "Filter by...",
            "Tipo de producto": "Product type",
            "Marca y modelo": "Brand and model",
            "Marca": "Brand",
            "Equivalencias": "Equivalencies",
            "para": "for",
            "Cerrar": "Close",
            "Redes sociales": "Contact us",

            "Inglés": "English",
            "Español": "Spanish",

            "TEXTO SOBRE NOSOTROS p1": "We are a factory located in the city of Rosario, Santa Fe, which since 1990 has been dedicated to the manufacture of electromechanical parts for motorcycles.",
            "TEXTO SOBRE NOSOTROS p2": "Since our beginning we have maintained the goal of producing quality spare parts at a good price, despite the bad times that we have had to live as a national industry.",
            "TEXTO SOBRE NOSOTROS p3": "Today the challenge is still present like that first day. For this reason we investigate and innovate in new ways to produce better and more efficiently, always thinking about the best we have, our customers.",

            "TopBar Nosotros": "About Us",
        }
    },
    es: {
        translation: {
            "Inicio": "Inicio",
            "Productos": "Productos",
            "Nuestros productos": "Nuestros productos",
            "Sobre nosotros": "Sobre nosotros",
            "Nosotros": "Nosotros",
            "Contactanos": "Contactanos",
            "Desde 1990 brindando productos de calidad": "Desde 1990 brindando productos de calidad",
            "Busque productos en nuestro catálogo": "Busque productos en nuestro catálogo",
            "Modelo": "Modelo",
            "Seleccione modelo": "Seleccione modelo",
            "Seleccione marca y modelo": "Seleccione marca y modelo",
            "Ingrese un código": "Ingrese un código",
            "Seleccione un tipo de producto": "Seleccione un tipo de producto",
            "Buscar": "Buscar",
            "Código": "Código",
            "Tipo": "Tipo",
            "Filtrar por...": "Filtrar por...",
            "Tipo de producto": "Tipo de producto",
            "Marca y modelo": "Marca y modelo",
            "Marca": "Marca",
            "Equivalencias": "Equivalencias",
            "para": "para",
            "Cerrar": "Cerrar",
            "Redes sociales": "Encontranos en:",

            "Inglés": "Inglés",
            "Español": "Español",

            "TEXTO SOBRE NOSOTROS p1": "Somos una fábrica ubicada en la ciudad de Rosario, Santa Fe, que desde el año 1990 nos dedicamos a la manufactura de piezas electromecánicas para motocicletas.",
            "TEXTO SOBRE NOSOTROS p2": "Desde nuestro comienzo hemos mantenido la meta de producir repuestos de calidad a buen precio, a pesar de los malos momentos que nos han tocado vivir como industria nacional.",
            "TEXTO SOBRE NOSOTROS p3": "Hoy el desafío sigue estando presente como aquel primer día. Por tal motivo nos capacitamos, investigamos e innovamos nuevas formas de producir mejor y más eficientemente, siempre pensando en lo mejor que tenemos que son ustedes, nuestros clientes.",

            "TopBar Nosotros": "Nosotros",
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "es",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;