import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "reactstrap";
import { SocialIcon } from "react-social-icons";
import { isMobile } from "react-device-detect";

import logoDados from "../lemmlogodados.png";
import { useAnalyticsEventTracker } from "../App";
import LanguageDropdown from "./LanguageDropdown";
import { useSelector } from "react-redux";
import { BsSearch } from "react-icons/bs";
import { RiContactsFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const TopBar = ({ style }) => {
  const gaEventTracker = useAnalyticsEventTracker("Top bar tracker");
  const routeSelector = useSelector((state) => state.app.routeSelected);
  const { t } = useTranslation();

  return (
    <Navbar
      className={`navbar-expand ${
        isMobile && "shadow-[0_35px_40px_-15px_rgba(0,0,0,0.3)]"
      }`}
      style={{ ...style, padding: 0 }}
    >
      <div
        className="d-flex flex-row justify-content-between"
        style={{ width: "inherit", height: 75 }}
      >
        <Link
          to={"/home"}
          onClick={() => gaEventTracker("click", "Logo home topbar")}
          className={"flex items-center"}
        >
          <img
            src={logoDados}
            alt="logodados"
            width={110}
            style={{ transform: "rotate(30deg)" }}
          ></img>
        </Link>
        {isMobile && (
          <div className="flex flex-row justify-around items-center gap-1">
            <div className="flex flex-col items-center">
              <div className=" text-white text-sm">{t("Redes sociales")}</div>
              <div className="flex flex-row gap-1">
                <SocialIcon
                  url="https://www.facebook.com/people/Lemm-Motoencendido/100017825223295/"
                  fgColor="white"
                  bgColor="#8F1D14"
                  style={{ height: 40, width: 40 }}
                  onClick={() =>
                    gaEventTracker("click", "Facebook icon mobile")
                  }
                />
                <SocialIcon
                  url="https://www.instagram.com/lemm_oficial?utm_source=qr&igsh=NHRzdGZuNGM4NnRr"
                  network="instagram"
                  fgColor="white"
                  bgColor="#8F1D14"
                  style={{ height: 40, width: 40 }}
                  onClick={() =>
                    gaEventTracker("click", "Instagram icon mobile")
                  }
                />
                <SocialIcon
                  url="https://wa.me/5493413593971"
                  network="whatsapp"
                  fgColor="white"
                  bgColor="#8F1D14"
                  style={{ height: 40, width: 40 }}
                  onClick={() =>
                    gaEventTracker("click", "WhatsApp icon mobile")
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center">
          {isMobile && (
            <div className="flex flex-row gap-[2px] items-center">
              <Link
                to={"/search"}
                onClick={() =>
                  gaEventTracker("click", "Productos button topbar mobile")
                }
                className={`sidebar-item no-underline`}
                style={
                  routeSelector === "/search"
                    ? { backgroundColor: "rgb(143, 29, 20)" }
                    : {}
                }
                href="#!"
              >
                <BsSearch size={26} color="white" />
              </Link>
              <Link
                to={"/us"}
                onClick={() =>
                  gaEventTracker("click", "Nosotros button topbar mobile")
                }
                className={`sidebar-item no-underline`}
                style={
                  routeSelector === "/us"
                    ? { backgroundColor: "rgb(143, 29, 20)" }
                    : {}
                }
                href="#!"
              >
                <RiContactsFill size={28} color="white" />
              </Link>
              <LanguageDropdown />
            </div>
          )}
          {!isMobile && (
            <div className="flex flex-row gap-1 items-center">
              <SocialIcon
                url="https://www.facebook.com/people/Lemm-Motoencendido/100017825223295/"
                fgColor="white"
                bgColor="#8F1D14"
                style={{ height: 50, width: 50 }}
                onClick={() => gaEventTracker("click", "Facebook icon")}
              />
              <SocialIcon
                url="https://www.instagram.com/lemm_oficial?utm_source=qr&igsh=NHRzdGZuNGM4NnRr"
                network="instagram"
                fgColor="white"
                bgColor="#8F1D14"
                style={{ height: 50, width: 50 }}
                onClick={() => gaEventTracker("click", "Instagram icon")}
              />
              <SocialIcon
                url="https://wa.me/5493413593971"
                network="whatsapp"
                fgColor="white"
                bgColor="#8F1D14"
                style={{ height: 50, width: 50 }}
                onClick={() => gaEventTracker("click", "WhatsApp icon")}
              />
              <LanguageDropdown />
            </div>
          )}
        </div>
      </div>
    </Navbar>
  );
};

export default TopBar;
