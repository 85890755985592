import React, { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  productosApi,
  useGetMarcasQuery,
  useGetModelosByMarcaQuery,
} from "../apis/productosApi";
import {
  changeFilterType,
  setIsProductosLoading,
  setProductos,
  setSearchInputValues,
} from "../slices/AppSlice";
import { useTranslation } from "react-i18next";

const tipoProductos = [
  { label: "CDI", value: "CDI" },
  { label: "REGULADORES", value: "REGULADORES" },
];

const SearchTypes = () => {
  const { t } = useTranslation();
  const [optionOpen, setOptionOpen] = useState("1");
  const filterType = useSelector((state) => state.app.filterType);
  const inputsValues = useSelector((state) => state.app.searchInputsValues);

  const dispatch = useDispatch();
  const {
    data: marcas,
    error: marcaError,
    isLoading: isMarcasLoading,
  } = useGetMarcasQuery();
  const { data: modelos, isLoading: isModeloLoading } =
    useGetModelosByMarcaQuery({ id: inputsValues.marcaModelo.idMarcaSelected });

  const toggle = (id) => {
    if (optionOpen === id) {
      setOptionOpen("");
    } else {
      setOptionOpen(id);
    }
  };

  const handleMarcaChange = (e) => {
    dispatch(
      setSearchInputValues({
        filterType: "marcaModelo",
        idMarcaSelected: e.value,
        idModeloSelected: null,
      })
    );
  };
  const handleModeloChange = (e) => {
    dispatch(
      setSearchInputValues({
        filterType: "marcaModelo",
        idMarcaSelected: inputsValues.marcaModelo.idMarcaSelected,
        idModeloSelected: e.value,
      })
    );
  };

  const [triggerSubmitMarcaModelo, { isLoading: pByMarcaModeloLoading }] =
    productosApi.endpoints.getProductosByMarcaModelo.useLazyQuery({
      marcaId: inputsValues.marcaModelo.idMarcaSelected,
      modeloId: inputsValues.marcaModelo.idModeloSelected,
    });
  const [triggerSubmitCodigo, { isLoading: pByCodigoLoading }] =
    productosApi.endpoints.getProductosByCodigo.useLazyQuery({
      codigo: inputsValues.codigo.codigo,
    });
  const [triggerSubmitTipoProducto, { isLoading: pByTipoLoading }] =
    productosApi.endpoints.getProductosByTipo.useLazyQuery({
      tipo: inputsValues.tipoProducto.tipo,
    });

  const isLoading = useMemo(
    () => pByMarcaModeloLoading || pByCodigoLoading || pByTipoLoading,
    [pByMarcaModeloLoading, pByCodigoLoading, pByTipoLoading]
  );

  useEffect(() => {
    dispatch(setIsProductosLoading(isLoading));
  }, [dispatch, isLoading]);

  const handleTipoProductoChange = (e) => {
    dispatch(
      setSearchInputValues({
        filterType: "tipoProducto",
        tipo: e.value,
      })
    );
  };

  const handleSubmit = async () => {
    if (filterType === "marcaModelo") {
      const response = await triggerSubmitMarcaModelo({
        marcaId: inputsValues.marcaModelo.idMarcaSelected,
        modeloId: inputsValues.marcaModelo.idModeloSelected,
      }).unwrap();
      dispatch(setProductos(response));
    }
    if (filterType === "codigo") {
      const response = await triggerSubmitCodigo({
        codigo: inputsValues.codigo.codigo,
      }).unwrap();
      dispatch(setProductos(response));
    }
    if (filterType === "tipoProducto") {
      const response = await triggerSubmitTipoProducto({
        tipo: inputsValues.tipoProducto.tipo,
      }).unwrap();
      dispatch(setProductos(response));
    }
  };

  useEffect(() => {
    if (optionOpen) {
      dispatch(changeFilterType({ filterType: optionOpen }));
    }
  }, [optionOpen, dispatch]);

  return (
    <div className="flex justify-center">
      <Card className="drop-shadow-md mb-4 md:w-1/2">
        <div className="flex justify-between">
          <CardTitle tag={"h6"} className={"px-4 pt-3 mb-0"}>
            {t("Busque productos en nuestro catálogo")}
          </CardTitle>
        </div>
        <CardBody>
          <Accordion open={optionOpen} toggle={toggle}>
            <AccordionItem className=" bg-red-400">
              <AccordionHeader targetId="marcaModelo">
                {t("Marca y modelo")}
              </AccordionHeader>
              <AccordionBody accordionId="marcaModelo" className="visible">
                {filterType === "marcaModelo" && isMarcasLoading && (
                  <Spinner color="danger" type="grow" />
                )}
                {filterType === "marcaModelo" &&
                  !isMarcasLoading &&
                  marcas &&
                  !marcaError && (
                    <>
                      <div>
                        <Label className="text-sm">{t("Marca")}</Label>
                        <Select
                          placeholder="Seleccione una marca"
                          defaultValue={{
                            label: marcas[0].marca,
                            value: marcas[0].id,
                          }}
                          onChange={handleMarcaChange}
                          options={marcas.map((m) => ({
                            label: m.marca,
                            value: m.id,
                          }))}
                          menuPortalTarget={document.querySelector("body")}
                        />
                      </div>
                      <div>
                        <Label className="text-sm">{t("Modelo")}</Label>
                        {isModeloLoading && (
                          <Spinner color="danger" type="grow" />
                        )}
                        {!isModeloLoading && modelos && (
                          <Select
                            onChange={handleModeloChange}
                            value={
                              inputsValues.marcaModelo.idModeloSelected == null
                                ? null
                                : {
                                    label: modelos.filter(
                                      (m) =>
                                        m.id ===
                                        inputsValues.marcaModelo
                                          .idModeloSelected
                                    )[0].descripcion,
                                    value: modelos.filter(
                                      (m) =>
                                        m.id ===
                                        inputsValues.marcaModelo
                                          .idModeloSelected
                                    )[0].id,
                                  }
                            }
                            defaultValue={{
                              label: modelos[0].descripcion,
                              value: modelos[0].id,
                            }}
                            options={modelos.map((modelo) => ({
                              label: modelo.descripcion,
                              value: modelo.id,
                            }))}
                            menuPortalTarget={document.querySelector("body")}
                            placeholder={t("Seleccione modelo")}
                          />
                        )}
                      </div>

                      <div className="flex justify-end mt-2">
                        <Button
                          title="Buscar"
                          disabled={
                            isMarcasLoading ||
                            isModeloLoading ||
                            inputsValues.marcaModelo.idModeloSelected == null
                          }
                          onClick={handleSubmit}
                          color="danger"
                          size="sm"
                        >
                          <span className="text-sm">{t("Buscar")}</span>
                        </Button>
                      </div>
                    </>
                  )}
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="codigo">{t("Código")}</AccordionHeader>
              <AccordionBody accordionId="codigo" className="visible">
                {filterType === "codigo" && (
                  <>
                    <div>
                      <Label className="text-sm">{t("Código")}</Label>
                      <Input
                        value={inputsValues.codigo.codigo}
                        onChange={(e) =>
                          dispatch(
                            setSearchInputValues({
                              filterType: "codigo",
                              codigo: e.target.value,
                            })
                          )
                        }
                      />
                    </div>

                    <div className="flex justify-end mt-2">
                      <Button
                        title="Buscar"
                        onClick={handleSubmit}
                        color="danger"
                        size="sm"
                      >
                        <span className="text-sm">{t("Buscar")}</span>
                      </Button>
                    </div>
                  </>
                )}
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="tipoProducto">
                {t("Tipo")}
              </AccordionHeader>
              <AccordionBody accordionId="tipoProducto" className="visible">
                {filterType === "tipoProducto" && (
                  <>
                    <div>
                      <Label className="text-sm">{t("Tipo")}</Label>
                      <Select
                        onChange={handleTipoProductoChange}
                        defaultValue={{
                          label: "CDI",
                          value: "CDI",
                        }}
                        options={tipoProductos}
                        menuPortalTarget={document.querySelector("body")}
                      />
                    </div>

                    <div className="flex justify-end mt-2">
                      <Button
                        title="Buscar"
                        onClick={handleSubmit}
                        color="danger"
                        size="sm"
                      >
                        <span className="text-sm">{t("Buscar")}</span>
                      </Button>
                    </div>
                  </>
                )}
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </CardBody>
      </Card>
    </div>
  );
};

export default SearchTypes;
