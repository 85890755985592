import React from "react";
import { useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
} from "reactstrap";
import { useLazyGetEquivalenciasByIdProductoQuery } from "../apis/productosApi";
import { useTranslation } from "react-i18next";

function EquivalenciasModal({ modalOpen, toggle, producto, ...args }) {
  const { t } = useTranslation();
  const [triggerEquivalencia, { isLoading, isError, data }] =
    useLazyGetEquivalenciasByIdProductoQuery({
      idProd: producto.id,
    });

  useEffect(() => {
    if (modalOpen) {
      triggerEquivalencia({ idProd: producto.id });
    }
  }, [modalOpen, producto, triggerEquivalencia]);

  return (
    <div>
      <Modal isOpen={modalOpen} size="md" centered scrollable {...args}>
        <ModalHeader tag={"h6"} style={{ backgroundColor: "rgb(143, 29, 20)", color: "white" }}>
          {t('Equivalencias')} {producto ? `${t('para')} ${producto.codigo}` : ""}
        </ModalHeader>
        <ModalBody>
          {isLoading && <Spinner color="danger" type="grow" />}
          {isError && <div>Ha ocurrido un error... oops</div>}
          {data && (
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('Marca')}</th>
                  <th>{t('Modelo')}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((e, index) => {
                  return (
                    <tr
                      key={Math.random()}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "rgb(252,165,165)" : "white",
                      }}
                    >
                      <td>{index}</td>
                      <td>{e.marca}</td>
                      <td>{e.modelo}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            {t('Cerrar')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default EquivalenciasModal;
