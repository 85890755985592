import { Button } from "reactstrap";
import {
  BsChevronLeft,
  BsChevronRight,
  BsHouseDoorFill,
  BsSearch,
  BsInfoCircleFill,
} from "react-icons/bs";
import { RiContactsFill } from "react-icons/ri";
import whatsapp from "../whatsapp.svg";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { toggleSidebar, changeRoute } from "../slices/AppSlice";

import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useMemo } from "react";
import { useAnalyticsEventTracker } from "../App";
import { useTranslation } from "react-i18next";

const MainSidebar = () => {
  const { t } = useTranslation();
  const gaEventTracker = useAnalyticsEventTracker("Sidebar tracker");
  const dispatch = useDispatch();
  const isSideBarOpen = useSelector((state) => state.app.isSideBarOpen);
  const routeSelector = useSelector((state) => state.app.routeSelected);

  const path = window.location.pathname;

  useEffect(() => {
    dispatch(changeRoute({ route: window.location.pathname }));
  }, [path, dispatch]);

  const sideBarDivStyle = useMemo(() => {
    if (!isMobile) {
      return `${isSideBarOpen ? "w-60" : "w-16"} sidebar-layout`;
    } else {
      return "w-16 sidebar-layout";
    }
  }, [isSideBarOpen, isMobile]); // eslint-disable-line

  return (
    <div className={sideBarDivStyle}>
      <div
        className="flex flex-col justify-between"
        style={{ height: "inherit" }}
      >
        <div>
          <div
            className={`${
              isSideBarOpen ? "w-60" : "w-16"
            } flex justify-end ease-in-out duration-300`}
          >
            <Button
              onClick={() => dispatch(toggleSidebar())}
              color="white"
              className={"m-3"}
              outline
              disabled={isMobile}
            >
              {isSideBarOpen ? (
                <BsChevronLeft color="white" />
              ) : (
                <BsChevronRight color="white" />
              )}
            </Button>
          </div>

          <ul className="px-1">
            <li>
              <Link
                to={"/home"}
                onClick={() => gaEventTracker("click", "Inicio button sidebar")}
                className={`sidebar-item no-underline`}
                style={
                  routeSelector === "/home"
                    ? { backgroundColor: "rgb(143, 29, 20)" }
                    : {}
                }
                href="#!"
              >
                {isSideBarOpen ? (
                  <div className="flex flex-row">
                    <BsHouseDoorFill size={28} color="white" />
                    <span className="ml-3 mt-1 text-white">
                      <b>{t("Inicio")}</b>
                    </span>
                  </div>
                ) : (
                  <BsHouseDoorFill size={28} color="white" />
                )}
              </Link>
            </li>
            <li>
              <Link
                to={"/search"}
                onClick={() =>
                  gaEventTracker("click", "Productos button sidebar")
                }
                className={`sidebar-item no-underline`}
                style={
                  routeSelector === "/search"
                    ? { backgroundColor: "rgb(143, 29, 20)" }
                    : {}
                }
                href="#!"
              >
                {isSideBarOpen ? (
                  <div className="flex flex-row">
                    <BsSearch size={28} color="white" />
                    <span className="ml-3 mt-1 text-white">
                      <b>{t("Productos")}</b>
                    </span>
                  </div>
                ) : (
                  <BsSearch size={28} color="white" />
                )}
              </Link>
            </li>
            <li>
              <Link
                to={"/us"}
                onClick={() =>
                  gaEventTracker("click", "Nosotros button sidebar")
                }
                className={`sidebar-item no-underline`}
                style={
                  routeSelector === "/us"
                    ? { backgroundColor: "rgb(143, 29, 20)" }
                    : {}
                }
                href="#!"
              >
                {isSideBarOpen ? (
                  <div className="flex flex-row">
                    <RiContactsFill size={28} color="white" />
                    <span className="ml-3 mt-1 text-white">
                      <b>{t("Nosotros")}</b>
                    </span>
                  </div>
                ) : (
                  <RiContactsFill size={28} color="white" />
                )}
              </Link>
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2 mb-8 ml-2 ease-in-out duration-500">
          <div className="flex flex-row items-center">
            <div
              className="ml-1"
              onClick={() => gaEventTracker("click", "WhatsApp button sidebar")}
            >
              <a href="https://wa.me/5493413593971">
                <img src={whatsapp} alt="whatsapp" width={40}></img>
              </a>
            </div>
            {isSideBarOpen ? (
              <div className="ml-2 pt-3">
                <div className="text-white">
                  <p className="text-md">
                    <b>{t("Contactanos")}</b>
                  </p>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex flex-row">
            <div className="ml-1">
              <BsInfoCircleFill size={32} color="white" />
            </div>
            {isSideBarOpen ? (
              <div className="ml-2">
                <div className="text-white">
                  <p className="text-xs my-0">Nicaragua Bis 383</p>
                  <p className="text-xs my-0">Rosario, Santa Fe, Argentina</p>
                </div>
                <div className="text-white">
                  <p className="text-xs">Tel: +54 (0341) 4576721</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSidebar;
