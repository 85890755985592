import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slices/AppSlice";

import { setupListeners } from '@reduxjs/toolkit/query'
import { productosApi } from './apis/productosApi'

export const store = configureStore({
  reducer: {
    app: appReducer,
    [productosApi.reducerPath]: productosApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productosApi.middleware),
});

setupListeners(store.dispatch)
