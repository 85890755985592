import { createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";
import { changeLanguage } from "i18next";

export const AppSlice = createSlice({
  name: "app",
  initialState: {
    language: 'AR',
    mobile: isMobile,
    isSideBarOpen: !isMobile,
    routeSelected: "/home",
    filterType: "marcaModelo",
    searchInputsValues: {
      marcaModelo: {
        filterType: "marcaModelo",
        idMarcaSelected: 1,
        idModeloSelected: 1,
      },
      codigo: {
        filterType: "codigo",
        codigo: "",
      },
      tipoProducto: {
        filterType: "tipoProducto",
        tipo: "CDI",
      },
    },
    fireSearchQuery: { marcaModelo: false, codigo: false, tipoProducto: false },
    productos: [],
    isProductosLoading: false,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    changeRoute: (state, action) => {
      state.routeSelected = action.payload.route;
    },
    changeFilterType: (state, action) => {
      state.filterType = action.payload.filterType;
    },
    setSearchInputValues: (state, action) => {
      if (action.payload.filterType === "marcaModelo") {
        state.searchInputsValues[action.payload.filterType] = {
          filterType: action.payload.filterType,
          idMarcaSelected: action.payload.idMarcaSelected,
          idModeloSelected: action.payload.idModeloSelected,
        };
      }
      if (action.payload.filterType === "codigo") {
        state.searchInputsValues[action.payload.filterType] = {
          filterType: action.payload.filterType,
          codigo: action.payload.codigo,
        };
      }
      if (action.payload.filterType === "tipoProducto") {
        state.searchInputsValues[action.payload.filterType] = {
          filterType: action.payload.filterType,
          tipo: action.payload.tipo,
        };
      }
    },
    mobileLayout: (state, action) => {
      state.mobile = action.payload.mobile;
    },
    changeCurrentLanguage: (state, action) => {
      state.language = action.payload.language;
      if (action.payload.language === "AR") {
        changeLanguage("es");
      }
      if (action.payload.language === "US") {
        changeLanguage("en");
      }
    },
    setProductos: (state, action) => {
      state.productos = action.payload;
    },
    setIsProductosLoading: (state, action) => {
      state.isProductosLoading = action.payload;
    }
  },
});

export const {
  toggleSidebar,
  changeRoute,
  mobileLayout,
  changeFilterType,
  setSearchInputValues,
  changeCurrentLanguage,
  setProductos,
  setIsProductosLoading,
} = AppSlice.actions;

export default AppSlice.reducer;
